import React from "react";

export default function CloudUploadIcon(props) {
  return (
    <svg width="102" height="48" viewBox="0 0 102 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M68.838 7.94341C70.3173 9.58578 72.7629 10.1182 74.8261 9.32512C77.053 8.46914 79.4717 8 82 8C93.0457 8 102 16.9543 102 28C102 39.0457 93.0457 48 82 48H51H18C8.05887 48 0 39.9411 0 30C0 20.0589 8.05887 12 18 12C20.0915 12 22.0997 12.3567 23.9671 13.0126C26.4513 13.8852 29.5054 12.9928 30.9559 10.7954C35.2493 4.2913 42.6235 0 51 0C58.0807 0 64.4451 3.06627 68.838 7.94341ZM44.2344 28.5938C43.7656 29.0156 43.0625 29.0625 42.5938 28.5938L41.5625 27.5625C41.1406 27.0938 41.1406 26.3906 41.5625 25.9688L50.7031 16.8281C51.125 16.4062 51.8281 16.4062 52.25 16.8281L61.3906 25.9688C61.8125 26.3906 61.8125 27.0938 61.3906 27.5625L60.3594 28.5938C59.8906 29.0156 59.1875 29.0156 58.7188 28.5469L53.375 22.9219V36.375C53.375 37.0312 52.8594 37.5 52.25 37.5H50.75C50.0938 37.5 49.625 37.0312 49.625 36.375V22.9219L44.2344 28.5938Z" fill="white"/>
    </svg>
  );
}

